import React from 'react'
import Layout from '../../components/Layout'

const Page = () => {
  return (<Layout>

        <h5>Dashboard</h5>

        <div className="card">
            <div className="card-body rounded-soft bg-white px-5 py-4">
                <h5 className="mt-0 mb-3">Welcome!</h5>
                <p className="mb-0">Please select an option from the menu.</p>
            </div>
        </div>
    </Layout>)
}

export default Page
